<template>
    <div class="wrap" style="padding-bottom: 0px">
        <ResendEmail :email="email" v-if="ShowResendEmail" @closeResendEmail="ShowResendEmail = false" />
        <div class="mo_space_header">
            <div class="header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                </a>
                <h2 style="margin-bottom: 0px"></h2>
                <div></div>
            </div>
        </div>
        <section class="login_main">
            <div class="login_main_box">
                <router-link
                    class="login_logo"
                    :to="{
                        name: 'Home'
                    }"
                >
                    <img src="/media/img/logo.svg" alt="" />
                </router-link>
                <div class="login_box">
                    <input
                        type="text"
                        placeholder="이메일 아이디"
                        id="email"
                        v-model="email"
                        @keyup.down="selectAutoComplete(0)"
                        @keypress="[(passwordNotExist = false), (idNotExist = false)]"
                        @keyup.enter="signIn"
                        @input="emailKeyPress()"
                        autocomplete="off"
                        aria-autocomplete="none"
                        @keydown.tab="showAutoComplte = false"
                    />
                    <input
                        type="password"
                        placeholder="비밀번호"
                        id="password"
                        v-model="password"
                        @keypress="[(passwordNotExist = false), (idNotExist = false)]"
                        @keyup.enter="signIn"
                    />

                    <ul
                        id="autocomplete"
                        v-if="showAutoComplte"
                        class="autocomplete"
                        v-click-outside="
                            () => {
                                showAutoComplte = false;
                            }
                        "
                    >
                        <li
                            class="autocomplete_item"
                            tabindex="0"
                            v-for="(item, index) in getEmailList"
                            :key="item"
                            @keyup.up="selectAutoComplete(index - 1)"
                            @keyup.down="selectAutoComplete(index === emailList.length - 1 ? emailList.length - 1 : index + 1)"
                            @keyup.enter="settingEmail($event.target)"
                            @click="settingEmail($event.target)"
                        >
                            <span> {{ email.split('@')[0] }}@{{ item }} </span>
                            <div class="border_line" v-if="index !== getEmailList.length - 1"></div>
                        </li>
                    </ul>
                </div>

                <div class="in_pw" v-if="passwordNotExist">
                    <p class="error_text">아이디 또는 비밀번호가 일치하지 않습니다.(남은 시도 횟수: {{ 10 - failCount }}회)</p>
                </div>
                <div class="in_pw" v-if="idNotExist">
                    <p class="error_text">아이디 또는 비밀번호가 일치하지 않습니다.</p>
                </div>
                <button @click="signIn()">로그인</button>
                <div class="auto_login">
                    <div id="login_check">
                        <checkboxAutoLogin v-model="autoLogin" />
                        <p>자동 로그인</p>
                    </div>
                    <div id="login_check1">
                        <checkboxSaveId v-model="saveId" />
                        <p>아이디 저장</p>
                    </div>
                    <div class="find_info_area">
                        <p class="find_id" @click="movePageFindId">아이디 찾기</p>
                        <p class="find_pw" @click="movePageFindPass">비밀번호 찾기</p>
                    </div>
                </div>
                <div class="btn_social" v-show="isShowSns">
                    <button class="social_item" @click="selectNaver">
                        <img src="/media/img/login/icon_login_naver.svg" />
                        <span class="login_tooltip" v-if="comeFrom === 'naver'" style="pointer-events: none"
                        >최근 로그인
                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.57971 7.97559C5.80991 9.30892 3.88541 9.30892 3.11561 7.97559L0.44818 3.35547C-0.32162 2.02213 0.640631 0.355468 2.18023 0.355468L7.51508 0.355468C9.05469 0.355468 10.0169 2.02214 9.24713 3.35547L6.57971 7.97559Z"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                    </button>
                    <button class="social_item" @click="selectKakao">
                        <img src="/media/img/login/icon_login_kakao.svg" />
                        <span class="login_tooltip" v-if="comeFrom === 'kakao'" style="pointer-events: none"
                        >최근 로그인
                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.57971 7.97559C5.80991 9.30892 3.88541 9.30892 3.11561 7.97559L0.44818 3.35547C-0.32162 2.02213 0.640631 0.355468 2.18023 0.355468L7.51508 0.355468C9.05469 0.355468 10.0169 2.02214 9.24713 3.35547L6.57971 7.97559Z"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                    </button>
                    <button class="social_item" @click="selectApple">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style="overflow: visible; background: white; border-radius: 50px"
                            width="42"
                            height="42"
                            viewBox="6 6 44 44"
                        >
                            <g fill="none" fill-rule="evenodd">
                                <path
                                    fill="#000"
                                    fill-rule="nonzero"
                                    d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z"
                                ></path>
                            </g>
                        </svg>
                        <span class="login_tooltip" v-if="comeFrom === 'apple'" style="pointer-events: none"
                        >최근 로그인
                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.57971 7.97559C5.80991 9.30892 3.88541 9.30892 3.11561 7.97559L0.44818 3.35547C-0.32162 2.02213 0.640631 0.355468 2.18023 0.355468L7.51508 0.355468C9.05469 0.355468 10.0169 2.02214 9.24713 3.35547L6.57971 7.97559Z"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                    </button>
                    <div id="naverIdLogin" style="display: none"></div>
                </div>
                <div class="btn-wrap" v-if="isShowSns">
                    <div class="ornot">
                        <p>또는</p>
                    </div>
                    <p class="link-signup">
                        <router-link :to="{ name: 'SignUp' }"><span>이메일로 회원가입</span></router-link>
                    </p>
                </div>

                <div class="find_info_wrap">
                    <span class="info_text" @click="movePageFindId">아이디 찾기</span>
                    <div class="align_center">
                        <div class="line_bar"></div>
                    </div>
                    <span class="info_text" @click="movePageFindPass">비밀번호 찾기</span>
                </div>
            </div>
        </section>

        <LoginBlock v-if="popupStatus" :blockTime="blockTime" @closeModal="popupStatus = false"></LoginBlock>
        <Complete v-if="dormantStatus_2" @closePopup="ConversionComplete" />
    </div>
</template>

<script>
import ResendEmail from '@/components/modal/SignUp/ResendEmail.vue';
import checkboxSaveId from '../components/checkbox/checkboxSaveId.vue';
import checkboxAutoLogin from '../components/checkbox/checkboxAutoLogin.vue';
import axios from '@/service/axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import { naverService } from '@/assets/js/naverLogin.js';
import LoginBlock from '@/components/modal/login/LoginBlock.vue';
import vClickOutside from 'v-click-outside';
import Complete from '@/components/modal/dormant/ConversionComplete.vue';
import { getUserCase } from '@/service/api/userApi';
import { getMySpaceList } from '@/service/api/profileApi';
import { USER_TYPE, getterUserType } from '@/assets/js/usertype';
const LoginComponent = {
  name: 'Login',
  props: {
    socialEmail: String,
    socialComeFrom: String,
    socialConversionDate: Date
  },
  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    ResendEmail,
    checkboxSaveId,
    checkboxAutoLogin,
    LoginBlock,
    Complete
  },

  data () {
    return {
      ShowResendEmail: false,
      autoLogin: false,
      saveId: false,
      passwordNotExist: false,
      idNotExist: false,
      blank: false,
      email: '',
      password: '',
      failCount: '',
      prevRoute: '',
      comeFrom: null,
      isApp: false,
      emailRegex: new RegExp('[a-z0-9]+@'),

      // 로그인 10회 이상 실패
      popupStatus: false,

      // 휴면 전환
      dormantInfo: {},
      dormantStatus_2: false,
      showAutoComplte: false,
      emailList: ['naver.com', 'gmail.com', 'hanmail.net', 'daum.net', 'kakao.com', 'nate.com', 'yahoo.com'],
      isShowSns: true
    };
  },

  created () {
    if (Object.keys(this.$route.params).length > 0) {
      const { isShowSns } = this.$route.params;
      this.isShowSns = isShowSns;
    }
    // 애플 로그인 이벤트리스너 등록
    // Listen for authorization success.
    document.addEventListener(
      'AppleIDSignInOnSuccess',
      event => {
        // Handle successful response.
        console.log('AppleIDSignInOnSuccess', event);
        const data = event.detail.authorization;
        this.appleSignUp(data.code, data.id_token, 'web');
      },
      { once: true }
    );
    // Listen for authorization failures.
    document.addEventListener(
      'AppleIDSignInOnFailure',
      event => {
        // Handle error.
        console.log('AppleIDSignInOnFailure', event);
      },
      { once: true }
    );

    this.getAppleJS();
    this.checkApp();
    const comeFrom = VueCookies.get('comeFrom');
    if (comeFrom != null) {
      this.comeFrom = comeFrom;
    }

    // 휴면 상태의 소셜 계정 복구
    if (this.socialEmail != null) {
      this.dormantInfo.email = this.socialEmail;
      this.dormantInfo.comeFrom = this.socialComeFrom;
      this.dormantInfo.conversionDate = this.socialConversionDate;
    }
  },

  methods: {
    emailKeyPress () {
      if (this.emailRegex.test(this.email)) {
        this.showAutoComplte = true;
      } else {
        this.showAutoComplte = false;
      }
    },
    settingEmail (val) {
      this.email = val.textContent.trim();
      const email = document.querySelector('#email');
      email.focus();
      this.showAutoComplte = false;
    },
    // 기기 체크
    checkApp () {
      this.$store.state.isApp === 'aplayzaos' ||
      this.$store.state.isApp === 'aplayzios' ||
      this.$store.state.isApp === 'aplayznativeaos'
        ? (this.isApp = true)
        : (this.isApp = false);
    },

    // 아이디 찾기 페이지로 이동
    movePageFindId () {
      router.push({ name: 'FindId' });
    },

    // 비밀번호 찾기 페이지로 이동
    movePageFindPass () {
      router.push({ name: 'FindPass' });
    },

    // 휴면 계정 전환 완료 후 이벤트
    ConversionComplete () {
      // 이메일, 비밀번호 초기화
      this.email = '';
      this.password = '';

      // 팝업 닫기
      this.dormantStatus_2 = false;
    },

    // 뒤로가기
    clickPre () {
      history.back();
    },

    generateRandomString (num) {
      // 네이버 인증 state 난수생성
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    selectNaver () {
      if (VueCookies.get('autoLogin') === 'Y') {
        VueCookies.set('autoLogin', 'S');
      }
      // 현재 주소 가져오기
      const url = window.location.href;
      // 주소에 따른 콜백 주소 변경
      const uri =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_CALLBACK_URL
          : process.env.VUE_APP_PRO_CALLBACK_URL;
      const clientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_NAVER_CRIENT_ID
          : process.env.VUE_APP_PRO_NAVER_CRIENT_ID;
      // state 난수생성
      const state = this.generateRandomString(20);
      const tag = document.createElement('a');
      tag.href =
        'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=' +
        clientId +
        '&redirect_uri=' +
        uri +
        '&state=' +
        state;
      tag.click();
    },

    selectKakao () {
      if (VueCookies.get('autoLogin') === 'Y') {
        VueCookies.set('autoLogin', 'S');
      }
      // 현재 주소 가져오기
      const url = window.location.href;
      const uri =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_CALLBACK_URL
          : process.env.VUE_APP_PRO_CALLBACK_URL;
      const clientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_KAKAO_CRIENT_ID
          : process.env.VUE_APP_PRO_KAKAO_CRIENT_ID;

      // a 태그 생성
      const tag = document.createElement('a');
      tag.href =
        'https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=' + clientId + '&redirect_uri=' + uri;

      // 카카오 로그인 페이지로 이동
      tag.click();
    },

    selectApple () {
      if (VueCookies.get('autoLogin') === 'Y') {
        VueCookies.set('autoLogin', 'S');
      }

      window.AppleID.auth.signIn();
    },

    getAppleJS () {
      const url = window.location.href;
      const uri =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_APPLE_CALLBACK_URL
          : process.env.VUE_APP_PRO_APPLE_CALLBACK_URL;
      const clientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_APPLE_CRIENT_ID
          : process.env.VUE_APP_PRO_APPLE_CRIENT_ID;

      window.AppleID.auth.init({
        clientId: clientId,
        scope: 'email',
        redirectURI: uri,
        usePopup: true // or false defaults to false
      });
    },

    appleSignUp (code, identityToken, state) {
      axios
        .post('/api/social/getAppleOAuthToken', { code, state })
        .then(res => {
          const data = res.data.result;
          if (res.status === 200) {
            VueCookies.set('socialAToken', data.access_token);
            VueCookies.set('socialRToken', data.refresh_token);

            this.appleLogin(identityToken);
          }
        })
        .catch(e => {
          console.log(e);
          if (e.response != null && e.response.data.error_code === 'KOE320') {
            router.replace({ name: 'Login' });
          }
        });
    },

    appleLogin (identityToken) {
      const accessToken = VueCookies.get('socialAToken');
      const comeFrom = VueCookies.get('comeFrom');

      // 쿠키 값이 없으면 email은 null
      let email = null;
      if (comeFrom === 'apple') {
        email = VueCookies.get('email');
      }

      axios
        .post('/api/social/appleCallback', { accessToken, email, identityToken })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (res.data.result.conversionDate == null) {
              VueCookies.set('userId', result.userId);
              if (result.accessToken != null && result.accessToken !== undefined) {
                // res 값으로 토큰이 있다면 로그인
                VueCookies.set('aToken', result.accessToken, '30d');
                VueCookies.set('rToken', result.refreshToken, '30d');

                this.checkSpace(result.email);
              } else {
                // 없다면 회원가입
                var userInfo = {};
                userInfo.comeFrom = result.comeFrom;
                userInfo.email = result.email;
                router.push({ name: 'SocialAgree', params: { userInfo: userInfo, backStatus: 'Y' } });
              }
            } else {
              // 휴면 계정 로그인 페이지로 이동
              router.push({
                name: 'Login',
                params: {
                  socialEmail: result.email,
                  socialComeFrom: result.comeFrom,
                  socialConversionDate: result.conversionDate
                }
              });
            }
          }
        })
        .catch(e => {
          console.log(e);
          const response = e.response.data;
          if (response.resultCd === '400' && response.resultMsg === '가입된 이메일 정보가 존재합니다.') {
            // 이미 등록되어 있는 아이디와 중복일 경우
            alert(e.response.data.resultMsg);
            router.push({ name: 'Login' });
          } else if (response.resultCd === '400' && response.resultMsg === '탈퇴 후 14일이 지나지 않은 이메일입니다.') {
            // 탈퇴 후 14일이 경과되지 않은 경우
            alert('탈퇴 후 14일이 지나지 않아 재가입이 불가합니다.  \n다른 메일로 가입 부탁드립니다.');
            router.push({ name: 'Login' });
          }
        });
    },

    checkSpace (email) {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios.post('/api/user/getLoginUser', { email }, { headers }).then(response => {
        const userId = response.data.result.userId;
        const email = response.data.result.email;
        const comeFrom = response.data.result.comeFrom;
        const userType = response.data.result.userType;
        const signupCouponAt = response.data.result.signupCouponAt;
        const division = response.data.result.division;
        // 쿠키 셋팅

        if (VueCookies.get('autoLogin') === 'S') {
          VueCookies.set('autoLogin', 'Y');
        }
        VueCookies.set('userId', userId);
        VueCookies.set('comeFrom', comeFrom);
        VueCookies.set('email', email);
        VueCookies.set('userType', userType);
        this.$store.commit('setEmail', email);
        this.$store.commit('setSignupCouponAt', signupCouponAt);
        this.$store.commit('setDivision', division);
        this.$store.commit('setRoute', response.data.route ? response.data.route : null);

        const spaceId = VueCookies.get('spaceId');

        if (spaceId != null) {
          axios
            .post('/api/profile/getMySpaceInfoCheck', { spaceId, userId }, { headers })
            .then(res2 => {
              if (res2.data.result) {
                // 이전 접속한 계정과 동일 계정이 접속
                axios
                  .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                  .then(res4 => {
                    if (res4.data.result.spacePayAt === 'Y' || res4.data.result.spacePayAt === 'T') {
                      // 이전 접속한 계정과 동일 계정일 경우 구독여부 체크
                      router.replace({ name: 'PlayerView', params: { spaceId: spaceId, setIsLoading: true } }); // 구독중
                    } else {
                      // 구독 중이 아닐 경우 해당 계정에 spaceId 유무 체크
                      // 쿠키에 저장된 spaceId 삭제
                      VueCookies.remove('spaceId');
                      axios
                        .post('/api/profile/checkSpace', { userId }, { headers })
                        .then(res5 => {
                          if (res5.data.resultCd === '0000') {
                            const result = res5.data.result;
                            let sCount = -1;
                            if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                            if (sCount === -1 || sCount === result.length) {
                              // 보유 스페이스가 없거나 전부 S인 경우
                              this.setUserGb(userId);
                              // router.push({ name: 'SpaceInsert' });
                            } else {
                              router.push({ name: 'MySpace' });
                            }
                          }
                        })
                        .catch(err5 => {
                          console.log(err5);
                        });
                    }
                  })
                  .catch(err4 => {
                    console.log(err4);
                  });
              } else {
                // 이전 접속한 계정과 다른 계정이 접속
                VueCookies.remove('spaceId');
                axios
                  .post('/api/profile/subscribeSpaceList', { userId }, { headers })
                  .then(response2 => {
                    if (response2.data.result.length > 1) {
                      // 구독중 spaceId가 2개 이상인 경우
                      router.push({ name: 'SpaceChoice' });
                    } else if (response2.data.result.length === 1) {
                      // 구독중 spaceId가 1개인 경우
                      VueCookies.set('spaceId', response2.data.result[0].spaceId);
                      // params: { setIsLoading: true }
                      router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                    }
                  })
                  .catch(error2 => {
                    var resultCd = error2.response.data.resultCd;
                    if (resultCd === 'D0001') {
                      // 구독중인 스페이스가 없는 경우
                      axios
                        .post('/api/profile/checkSpace', { userId }, { headers })
                        .then(response3 => {
                          if (response3.data.resultCd === '0000') {
                            const result = response3.data.result;
                            let sCount = -1;
                            if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                            if (sCount === -1 || sCount === result.length) {
                              // 보유 스페이스가 없거나 전부 S인 경우
                              this.setUserGb(userId);
                              // router.push({ name: 'SpaceInsert' });
                            } else {
                              router.push({ name: 'MySpace' });
                            }
                          }
                        })
                        .catch(error3 => {
                          console.log(error3);
                        });
                    }
                  });
              }
            })
            .catch(err2 => {
              console.log(err2);
            });
        } else {
          axios
            .post('/api/profile/subscribeSpaceList', { userId }, { headers })
            .then(response2 => {
              if (response2.data.result.length > 1) {
                // 구독중 spaceId가 2개 이상인 경우
                router.push({ name: 'SpaceChoice' });
              } else if (response2.data.result.length === 1) {
                // 구독중 spaceId가 1개인 경우
                VueCookies.set('spaceId', response2.data.result[0].spaceId);
                router.push({ name: 'PlayerView', params: { setIsLoading: true } });
              }
            })
            .catch(error2 => {
              var resultCd = error2.response.data.resultCd;
              if (resultCd === 'D0001') {
                // 구독중인 스페이스가 없는 경우
                axios
                  .post('/api/profile/checkSpace', { userId }, { headers })
                  .then(response3 => {
                    if (response3.data.resultCd === '0000') {
                      const result = response3.data.result;
                      let sCount = -1;
                      if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                      if (sCount === -1 || sCount === result.length) {
                        // 보유 스페이스가 없거나 전부 S인 경우
                        // router.push({ name: 'SpaceInsert' });
                        this.setUserGb(userId);
                      } else {
                        router.push({ name: 'MySpace' });
                      }
                    }
                  })
                  .catch(error3 => {
                    console.log(error3);
                  });
              }
            });
        }
      });
    },

    signIn () {
      this.showAutoComplte = false;
      const email = this.email.trim();
      const comeFrom = 'email';
      const password = btoa(this.password.trim());
      var deviceToken = '';
      var deviceOs = '';
      if (email.length > 0 && password.length > 0) {
        axios
          .post('/api/auth/login', { email, password, deviceToken, deviceOs })
          .then(res => {
            if (res.data.result.conversionDate == null) {
              this.loginAuto();
              this.idSave();
              // 이메일, 패스워드 일치
              this.passwordNotExist = false;
              const aToken = res.data.result.accessToken;
              const rToken = res.data.result.refreshToken;

              VueCookies.set('aToken', aToken, '30d');
              VueCookies.set('rToken', rToken, '30d');
              VueCookies.set('email', email, '30d');
              const headers = { 'X-AUTH-TOKEN': aToken };
              axios.post('/api/user/mailAcceptCheck', { email, comeFrom }, { headers }).then(res3 => {
                if (res3.data.result) {
                  // 이메일 인증 완료
                  axios
                    .post('/api/user/getLoginUser', { email }, { headers })
                    .then(response => {
                      // 휴면 계정 처리 추가 부분
                      const result = response.data.result;
                      const userId = result.userId;
                      const email = result.email;
                      const comeFrom = result.comeFrom;
                      const userType = result.userType;
                      const signupCouponAt = result.signupCouponAt;
                      const division = result.division;
                      // 쿠키 셋팅
                      VueCookies.set('userId', userId);
                      VueCookies.set('email', email);
                      VueCookies.set('comeFrom', comeFrom);
                      VueCookies.set('userType', userType);
                      this.$store.commit('setEmail', email);
                      this.$store.commit('setSignupCouponAt', signupCouponAt);
                      this.$store.commit('setDivision', division);
                      this.$store.commit('setRoute', result.route ? result.route : null);

                      const spaceId = VueCookies.get('spaceId');
                      const aToken = VueCookies.get('aToken');
                      const headers = { 'X-AUTH-TOKEN': aToken };

                      // 페이지 이동
                      if (spaceId != null) {
                        // 쿠키에 spaceId가 있을때
                        axios
                          .post('/api/profile/getMySpaceInfoCheck', { spaceId, userId }, { headers })
                          .then(res2 => {
                            if (res2.data.result) {
                              // 이전 접속한 계정과 동일 계정이 접속
                              axios
                                .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                                .then(res4 => {
                                  if (res4.data.result.spacePayAt === 'Y' || res4.data.result.spacePayAt === 'T') {
                                    // 이전 접속한 계정과 동일 계정일 경우 구독 or 체험여부 체크
                                    router.replace({
                                      name: 'PlayerView',
                                      params: { spaceId: spaceId, setIsLoading: true }
                                    }); // 구독 or 체험중
                                  } else {
                                    // 구독 or 체험중이 아닐 경우 해당 계정에 spaceId 유무 체크
                                    // 쿠키에 저장된 spaceId 삭제
                                    VueCookies.remove('spaceId');
                                    axios
                                      .post('/api/profile/checkSpace', { userId }, { headers })
                                      .then(res5 => {
                                        if (res5.data.resultCd === '0000') {
                                          const result = res5.data.result;
                                          let sCount = -1;
                                          if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                                          if (sCount === -1 || sCount === result.length) {
                                            // 보유 스페이스가 없거나 전부 S인 경우
                                            this.setUserGb(userId);
                                          } else {
                                            router.replace({ name: 'MySpace' });
                                          }
                                        }
                                      })
                                      .catch(err5 => {
                                        console.log(err5);
                                      });
                                  }
                                })
                                .catch(err4 => {
                                  console.log(err4);
                                });
                            } else {
                              // 이전 접속한 계정과 다른 계정이 접속
                              VueCookies.remove('spaceId');
                              axios
                                .post('/api/profile/subscribeSpaceList', { userId }, { headers })
                                .then(response2 => {
                                  if (response2.data.resultCd === '0000') {
                                    const result = response2.data.result;
                                    if (result.length > 1) {
                                      // 구독 or 체험중 spaceId가 2개 이상인 경우
                                      router.replace({ name: 'SpaceChoice' });
                                    } else if (result.length === 1) {
                                      // 구독 or 체험중 spaceId가 1개인 경우
                                      VueCookies.set('spaceId', result[0].spaceId);
                                      router.replace({ name: 'PlayerView', params: { setIsLoading: true } });
                                    }
                                  }
                                })
                                .catch(error2 => {
                                  const resultCd = error2.response.data.resultCd;
                                  if (resultCd === 'D0001') {
                                    // 구독 or 체험중인 스페이스가 없는 경우
                                    axios
                                      .post('/api/profile/checkSpace', { userId }, { headers })
                                      .then(response3 => {
                                        if (response3.data.resultCd === '0000') {
                                          const result = response3.data.result;
                                          let sCount = -1;
                                          if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                                          if (sCount === -1 || sCount === result.length) {
                                            // 보유 스페이스가 없거나 전부 S인 경우
                                            this.setUserGb(userId);
                                            // router.replace({ name: 'SpaceInsert' });
                                          } else {
                                            router.replace({ name: 'MySpace' });
                                          }
                                        }
                                      })
                                      .catch(error3 => {
                                        console.log(error3);
                                      });
                                  }
                                });
                            }
                          })
                          .catch(err2 => {
                            console.log(err2);
                          });
                      } else {
                        // 쿠키에 spaceId가 없을때
                        axios
                          .post('/api/profile/subscribeSpaceList', { userId }, { headers })
                          .then(response2 => {
                            if (response2.data.result.length > 1) {
                              // 구독중 spaceId가 2개 이상인 경우
                              router.replace({ name: 'SpaceChoice' });
                            } else if (response2.data.result.length === 1) {
                              // 구독중 spaceId가 1개인 경우
                              VueCookies.set('spaceId', response2.data.result[0].spaceId);
                              router.replace({
                                name: 'PlayerView',
                                params: {
                                  setIsLoading: true
                                }
                              });
                            }
                          })
                          .catch(error2 => {
                            const resultCd = error2.response.data.resultCd;
                            if (resultCd === 'D0001') {
                              // 구독중인 스페이스가 없는 경우
                              axios
                                .post('/api/profile/checkSpace', { userId }, { headers })
                                .then(response3 => {
                                  if (response3.data.resultCd === '0000') {
                                    const result = response3.data.result;
                                    let sCount = -1;
                                    if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                                    if (sCount === -1 || sCount === result.length) {
                                      // 보유 스페이스가 없거나 전부 S인 경우
                                      this.setUserGb(userId);
                                    } else {
                                      getMySpaceList(userId, 10, null).then(res => {
                                        const { resultCd, result } = res.data;
                                        if (resultCd === '0000') {
                                          const { spaceInfoListInfo } = result;
                                          const { spaceId } = spaceInfoListInfo[spaceInfoListInfo.length - 1];
                                          this.$cookies.set('spaceId', spaceId);
                                          this.$router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                                        }
                                      });
                                      // router.replace({ name: 'MySpace' });
                                    }
                                  }
                                })
                                .catch(error3 => {
                                  console.log(error3);
                                });
                            }
                          });
                      }
                    })
                    .catch(error => {
                      console.log(error);
                    });
                } else {
                  // 이메일 인증 미완료
                  this.ShowResendEmail = true;
                }
              });
            } else {
              // 휴면 전환 팝업
              this.dormantInfo.email = email;
              this.dormantInfo.comeFrom = 'email';
              this.dormantInfo.conversionDate = res.data.result.conversionDate;
            }
          })
          .catch(err => {
            console.log(err);
            const resultCd = err.response.data.resultCd;
            if (resultCd === 'L2001') {
              if (err.response.data.resultMsg === '아이디가 존재하지 않거나 비밀번호가 일치하지 않습니다') {
                this.idNotExist = true;
                this.passwordNotExist = false;
              } else {
                this.failCount = err.response.data.resultMsg;
                this.passwordNotExist = true;
                this.idNotExist = false;
              }
              this.password = '';
            } else if (resultCd === 'M1006') {
              this.ShowResendEmail = true;
            } else if (resultCd === '6001') {
              this.idNotExist = true;
              this.passwordNotExist = false;
              this.password = '';
            } else if (resultCd === 'L2012') {
              this.popupStatus = true;
              this.blockTime = err.response.data.resultMsg;
            }
          });
      } else {
        // 이메일 또는 패스워드 빈칸
        alert('이메일 또는 패스워드를 입력해 주세요!');
        document.getElementById('email').focus();
      }
    },
    idSave () {
      this.saveId
        ? VueCookies.set('saveId', this.saveId, 10 * 365 * 24 * 60 * 60 * 1000)
        : VueCookies.set('saveId', this.saveId, 10 * 365 * 24 * 60 * 60 * 1000);
    },
    loginAuto () {
      this.autoLogin
        ? VueCookies.set('autoLogin', 'Y', 10 * 365 * 24 * 60 * 60 * 1000)
        : VueCookies.set('autoLogin', 'N', 10 * 365 * 24 * 60 * 60 * 1000);
    },
    selectAutoComplete (index) {
      const autocomplete = document.querySelector('#autocomplete');
      if (autocomplete && index > -1) {
        autocomplete.childNodes[index].focus();
        autocomplete.childNodes[index].classList.add('focus');
      } else if (index < 0) {
        const email = document.querySelector('#email');
        email.focus();
      }
    },
    setUserGb (userId = '') {
      getUserCase(userId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          const usertype = getterUserType(result.trialYn, result.spaceYn);

          if (usertype === USER_TYPE.FREE_NO_SPACE || usertype === USER_TYPE.END_FREE_NO_SPACE) {
            router.replace({ name: 'PlayerView', params: { setIsLoading: true } });
          } else {
            router.replace({ name: 'SpaceInsert' });
          }
        }
      });
    }
  },
  computed: {
    getEmailList () {
      const splitArr = this.email.split('@');
      if (splitArr[splitArr.length - 1]) {
        return this.emailList.filter(item => item.startsWith(splitArr[splitArr.length - 1]));
      }

      return this.emailList;
    }
  },
  mounted () {
    naverService().setNaver();
    setTimeout(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('aplayzaos') !== -1) {
        document.getElementsByClassName('mo_space_header')[0].style.padding = '12px 0 0';
      } else if (userAgent.indexOf('aplayznativeaos') !== -1) {
        document.getElementsByClassName('mo_space_header')[0].style.padding = '12px 0 0';
      }
    }, 1000);
    if (this.$store.getters.isLogin) {
      this.$store.commit('setSpaceId', '');
      history.go(1);
    }
    if (VueCookies.get('saveId') === 'true') {
      this.saveId = true;
      this.email = VueCookies.get('email');
    } else {
      this.saveId = false;
    }
  },
  beforeMount () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.search('aplayzNativeAos') > 0) {
      router.replace({ name: 'Update' }).catch(() => {});
    }
  }
};
export default LoginComponent;
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped>
.find_info_area {
  position: absolute;
  right: 0;
  margin-right: 0;
}

.find_info_wrap {
  display: none;
}

.find_info_area .find_id,
.find_info_area .find_pw {
  color: rgb(225 225 225 / 80%);
  text-decoration: none;
  font-family: 'Noto Sans KR', 'LaTo', 'sans-serif';
  font-weight: 400;
  letter-spacing: -0.02em;
  transition: ease-out 0.3s;
  font-size: 14px;
}

.login_box {
  position: relative;
}

.autocomplete {
  position: absolute;
  top: 57px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.6);
  background-color: var(--black141414);
  z-index: 99;
  padding: 0;
}

.autocomplete_item {
  background-color: var(--black141414);
}

.autocomplete_item:hover {
  background: rgba(255, 255, 255, 0.06);
}

.autocomplete_item {
  color: var(--whitefff);
  font-size: 15px;
  line-height: 22px;
  padding: 14px 16px 0 16px;
  cursor: pointer;
}

.border_line {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 0.16);
}

.autocomplete_item:last-child {
  padding-bottom: 16px;
}

@media all and (max-width: 500px) {
  .find_info_area {
    display: none;
  }

  .find_info_wrap {
    display: flex;
    justify-content: space-between;
    max-width: 254px;
    width: 100%;
    margin: 0 auto;
    margin-top: 43px;
  }

  .line_bar {
    width: 1px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .find_info_wrap .info_text {
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.64);
    cursor: pointer;
  }
}
</style>

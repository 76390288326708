import { getCookie } from '@/utils/Utils';
// ENUM
const USER_TYPE = {
  FREE_NO_SPACE: 1, // 무료체험중 스페이스 없는 유저
  FREE_HAS_SPACE: 2, // 해당스페이스가 무료 체험인경우
  END_FREE: 3, // 무료체험종료
  SUBSCRIBE: 4, // 구독자
  END_FREE_NO_SPACE: 5 // 무료체험종료 - 구독중인 스페이스도 없는 경우
};

const getterUserType = (trialYn = 'N', spaceYn = 'N', subscribelist = []) => {
  const spaceId = getCookie('spaceId');
  // FREE_NO_SPACE : 무료 체험중인 스페이스
  if (trialYn === 'Y' && spaceYn === 'N') {
    return USER_TYPE.FREE_NO_SPACE;
  } else if (trialYn === 'Y' && spaceYn === 'Y') {
    if (subscribelist.length === 0) {
      // 무료 체험이 종료되고, 구독중인 스페이스가 없는 경우
      return USER_TYPE.END_FREE_NO_SPACE;
    } else {
      const findSpace = subscribelist.find((item) => item.spaceId === spaceId);

      if (findSpace) {
        if (findSpace.spacePayAt === 'Y' || findSpace.spacePayAt === 'T') {
          return USER_TYPE.SUBSCRIBE;
        } else {
          return USER_TYPE.END_FREE;
        }
      }
    }
    return USER_TYPE.FREE_HAS_SPACE;
  } else if (trialYn === 'N' && spaceYn === 'Y') {
    if (subscribelist.length === 0) {
      // 무료 체험이 종료되고, 구독중인 스페이스가 없는 경우
      return USER_TYPE.END_FREE;
    } else {
      const findSpace = subscribelist.find((item) => item.spaceId === spaceId);

      if (findSpace) {
        return USER_TYPE.SUBSCRIBE;
      }
    }
    return USER_TYPE.END_FREE;
  } else if (trialYn === 'N' && spaceYn === 'N') {
    return USER_TYPE.END_FREE_NO_SPACE;
  }
  return USER_TYPE.FREE_NO_SPACE;
};

Object.freeze(USER_TYPE);

export { USER_TYPE, getterUserType };
